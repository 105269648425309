<template>
  <vbt-content-box
    :loading="loading"
    :loading-error="loadingError"
    title="Information"
  >
    <template #toolbar>
      <vbt-dialog
        v-model="vendorReturnTrackingNumberAdderDialog"
        :disable-submit-btn="!vendorReturnTrackingNumber"
        title="Vendor Return Tracking Number"
        submit-btn-name="Add"
        @submit="addVendorReturnTrackingNumber"
        @close="resetForm"
      >
        <template #activator="{ on }">
          <v-btn
            x-small
            outlined
            autofocus
            color="success"
            v-on="on"
          >
            Add vendor return tracking number
          </v-btn>
        </template>

        <v-text-field
          v-model.trim="vendorReturnTrackingNumber"
          class="vbt-hide-form-control-details mt-3"
          label="Tracking Number"
          @keypress.enter="addVendorReturnTrackingNumber"
        />
      </vbt-dialog>
    </template>

    <v-layout align-center>
      <v-flex sm4>
        <v-layout align-center>
          <strong class="mr-2">
            Tracking Number:
          </strong>

          <router-link
            v-if="returnedPackage.trackingNumber"
            :to="{
              name: 'tracking',
              query: {
                pageNum: '1',
                trackingNumber: returnedPackage.trackingNumber,
              },
            }"
            target="_blank"
            class="blue--text darken-1"
          >
            {{ returnedPackage.trackingNumber }}
          </router-link>
          <span v-else>
            -
          </span>

          <ir-returned-package-track-barcode-viewer-activator
            v-if="returnedPackage.trackingNumber"
            @click="openBarcodeViewer(returnedPackage.trackingNumber)"
          />
        </v-layout>
      </v-flex>

      <v-flex sm4>
        <v-layout align-center>
          <strong class="mr-2">
            Vendor Return Tracking Number:
          </strong>

          <router-link
            v-if="returnedPackage.vendorReturnTrackingNumber"
            :to="{
              name: 'tracking',
              query: {
                pageNum: '1',
                trackingNumber: returnedPackage.vendorReturnTrackingNumber,
              },
            }"
            target="_blank"
            class="success--text darken-1"
          >
            {{ returnedPackage.vendorReturnTrackingNumber }}
          </router-link>
          <span v-else>
            -
          </span>

          <ir-returned-package-track-barcode-viewer-activator
            v-if="returnedPackage.vendorReturnTrackingNumber"
            color="success--text darken-1"
            @click="openBarcodeViewer(returnedPackage.vendorReturnTrackingNumber)"
          />
        </v-layout>
      </v-flex>

      <v-flex sm4>
        <v-layout align-center>
          <strong class="mr-2">
            Order ID:
          </strong>

          <router-link
            v-if="returnedPackage.orderId"
            :to="{
              name: 'order',
              params: { id: returnedPackage.orderId },
            }"
            class="blue--text darken-1"
          >
            {{ returnedPackage.orderId }}
          </router-link>
          <span v-else>
            -
          </span>
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout
      class="mt-2"
      align-center
    >
      <v-flex sm4>
        <v-layout align-center>
          <strong class="mr-2">
            Expire Date:
          </strong>

          {{ returnedPackage.expireDate | formatDate('local', '-') }}
        </v-layout>
      </v-flex>

      <v-flex sm4>
        <v-layout align-center>
          <strong class="mr-2">
            Provider:
          </strong>

          {{ returnedPackage.printProvider }}
        </v-layout>
      </v-flex>

      <v-flex sm4>
        <v-layout align-center>
          <strong class="mr-2">
            Client:
          </strong>

          {{ returnedPackage.printCustomer }}
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout
      class="mt-2"
      align-center
    >
      <v-flex sm4>
        <v-layout align-center>
          <strong class="mr-2">
            Description:
          </strong>

          {{ returnedPackage.description }}
        </v-layout>
      </v-flex>

      <v-flex sm4>
        <v-layout align-center>
          <strong class="mr-2">
            Resend To Client:
          </strong>

          <v-icon
            v-if="typeof returnedPackage.resendToCustomer === 'boolean'"
            :color="returnedPackage.resendToCustomer ? 'success' : 'error'"
          >
            {{ returnedPackage.resendToCustomer ? 'check' : 'block' }}
          </v-icon>
          <template v-else>
            -
          </template>
        </v-layout>
      </v-flex>

      <v-flex sm4>
        <v-layout align-center>
          <strong class="mr-2">
            Reason:
          </strong>

          {{ returnedPackage.reasonDescription }}
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout
      class="mt-2"
      align-center
    >
      <v-flex sm4>
        <v-layout align-center>
          <strong class="mr-2">
            Reshipping Accepted:
          </strong>

          <v-icon
            v-if="typeof returnedPackage.chargeReshipping === 'boolean'"
            :color="returnedPackage.chargeReshipping ? 'success' : 'error'"
          >
            {{ returnedPackage.chargeReshipping ? 'check' : 'block' }}
          </v-icon>
          <template v-else>
            -
          </template>
        </v-layout>
      </v-flex>

      <v-flex sm4>
        <v-layout align-center>
          <strong class="mr-2">
            Reshipable:
          </strong>

          <v-icon
            v-if="typeof returnedPackage.productReshipable === 'boolean'"
            :color="returnedPackage.productReshipable ? 'success' : 'error'"
          >
            {{ returnedPackage.productReshipable ? 'check' : 'block' }}
          </v-icon>
          <template v-else>
            -
          </template>
        </v-layout>
      </v-flex>

      <ir-returned-package-track-barcode ref="trackBarcodeViewer" />
    </v-layout>
  </vbt-content-box>
</template>

<script>
import { mapState } from 'vuex';

import { wrapToLoadingFn } from '@helpers';

import { ReturnPackageApiService } from '../_services/returnPackages.api.service';

import IrReturnedPackageTrackBarcodeViewerActivator from './IrReturnedPackageTrackBarcodeViewerActivator.vue';
import IrReturnedPackageTrackBarcode from './IrReturnedPackageTrackBarcode.vue';

export default {
  name: 'ReturnedPackageInfo',

  props: {
    loadingError: {
      type: Object,
      default: () => ({}),
    },
    loading: Boolean,
  },

  components: {
    IrReturnedPackageTrackBarcodeViewerActivator,
    IrReturnedPackageTrackBarcode,
  },

  data() {
    return {
      vendorReturnTrackingNumber: '',
      vendorReturnTrackingNumberAdderDialog: false,
    };
  },

  computed: {
    ...mapState('returnPackages', ['returnedPackage']),
  },

  methods: {
    addVendorReturnTrackingNumber() {
      if (!this.vendorReturnTrackingNumber) {
        return;
      }

      this.$VBlackerTheme.alert.warning({
        text: 'Add tracking number?',
      }, () => this.wrapToLoadingFn({
        req: ReturnPackageApiService.addVendorReturnTrackingNumber.bind({}, {
          trackingNumber: this.vendorReturnTrackingNumber,
          id: this.$route.params.id,
        }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: () => {
          this.$VBlackerTheme.notification.success('Successfully added');
          this.vendorReturnTrackingNumberAdderDialog = false;
          this.$emit('refresh');
        },
      }));
    },

    resetForm() {
      this.vendorReturnTrackingNumber = '';
    },

    openBarcodeViewer(trackNum) {
      this.$refs.trackBarcodeViewer.$_openDialog(trackNum);
    },

    wrapToLoadingFn,
  },
};
</script>
