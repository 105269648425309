<template>
  <vbt-content-box
    :loading="loading"
    :loading-error="loadingError"
    title="History"
  >
    <vbt-table
      :headers="headers"
      :items="returnedPackageHistory"
      show-paginator
    >
      <template #processDate="{ item: { processDate: _date } }">
        {{ _date | formatDate('local', '-') }}
      </template>

      <template #actions="{ item }">
        <v-layout v-if="item.recepient">
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                icon
                small
                color="info"
                v-on="on"
                @click="showHistoryItemRequest(item.id)"
              >
                <v-icon>
                  remove_red_eye
                </v-icon>
              </v-btn>
            </template>

            Show request
          </v-tooltip>
        </v-layout>
      </template>
    </vbt-table>

    <vbt-dialog
      v-model="requestCodeDialog"
      title="Request body"
      hide-submit-btn
    >
      <vbt-code
        :value="requestCode"
        readonly
      />
    </vbt-dialog>
  </vbt-content-box>
</template>

<script>
import { mapState } from 'vuex';

import { wrapToLoadingFn } from '@helpers';

import { ReturnPackageApiService } from '../_services/returnPackages.api.service';

const headers = Object.freeze([
  { text: 'Number', value: 'id' },
  { text: 'Process Date', value: 'processDate' },
  { text: 'Description', value: 'description' },
  { text: 'Recepient', value: 'recepient' },
  { text: '', value: 'actions', width: '20px' },
]);

export default {
  name: 'ReturnedPackageHistory',

  props: {
    loadingError: {
      type: Object,
      default: () => ({}),
    },
    loading: Boolean,
  },

  data() {
    return {
      headers,

      requestCode: '',
      requestCodeDialog: false,
    };
  },

  computed: {
    ...mapState('returnPackages', {
      returnedPackageHistory: ({ returnedPackage: { history: h } }) => h || [],
    }),
  },

  methods: {
    showHistoryItemRequest(historyId) {
      this.wrapToLoadingFn({
        req: ReturnPackageApiService.getReturnedPackageHistoryItemRequest.bind({}, {
          id: this.$route.params.id,
          historyId,
        }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: (data) => {
          if (data) {
            this.requestCode = JSON.stringify(data, null, '\t');
            this.requestCodeDialog = true;
          } else {
            this.$VBlackerTheme.notification.warning('Empty request');
          }
        },
      });
    },

    wrapToLoadingFn,
  },
};
</script>
