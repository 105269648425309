<template>
  <vbt-content-box
    :loading="loading"
    :loading-error="loadingError"
    :no-content="noContent"
    title="Customer Data"
  >
    <v-layout align-center>
      <v-flex sm3>
        <v-layout align-center>
          <strong class="mr-2">
            Ship Method:
          </strong>

          {{ customerData.shipMethod || '-' }}
        </v-layout>
      </v-flex>

      <v-flex sm3>
        <v-layout align-center>
          <strong class="mr-2">
            Ship Service:
          </strong>

          {{ customerData.shipService || '-' }}
        </v-layout>
      </v-flex>

      <v-flex sm3>
        <v-layout align-center>
          <strong class="mr-2">
            Max Ship Cost:
          </strong>

          {{ customerData.maxShippingCost || '-' }}
        </v-layout>
      </v-flex>

      <v-flex sm3>
        <v-layout align-center>
          <strong class="mr-2">
            Expire Date:
          </strong>

          {{ customerData.deliveryDate | formatDate('local', '-') }}
        </v-layout>
      </v-flex>
    </v-layout>

    <v-layout
      justify-space-between
      align-center
      class="my-3"
    >
      <v-divider />

      <i class="px-2">
        Return Address
      </i>

      <v-divider />
    </v-layout>

    <v-layout align-center>
      <v-flex sm3>
        <strong class="mr-2">
          Company:
        </strong>
        {{ customerData.returnAddress.company || '-' }}
      </v-flex>

      <v-flex sm3>
        <strong class="mr-2">
          Phone Number:
        </strong>
        {{ customerData.returnAddress.phoneNumber || '-' }}
      </v-flex>

      <v-flex sm3>
        <strong class="mr-2">
          Attention:
        </strong>
        {{ customerData.returnAddress.attention || '-' }}
      </v-flex>
    </v-layout>

    <v-layout
      align-center
      class="mt-2"
    >
      <v-flex sm6>
        <strong class="mr-2">
          Street:
        </strong>
        {{ street }}
      </v-flex>

      <v-flex s6>
        <strong class="mr-2">
          Place:
        </strong>

        {{ customerData.returnAddress.country ? `${customerData.returnAddress.country},` : '' }}
        {{ customerData.returnAddress.city ? `${customerData.returnAddress.city},` : '' }}
        {{ customerData.returnAddress.state }} {{ customerData.returnAddress.zip }}
      </v-flex>
    </v-layout>
  </vbt-content-box>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ReturnedPackageCustomerData',

  props: {
    loadingError: {
      type: Object,
      default: () => ({}),
    },
    loading: Boolean,
  },

  computed: {
    ...mapState('returnPackages', {
      noContent: ({ returnedPackage: { customerData } }) => !customerData,
      customerData: ({ returnedPackage: { customerData } }) => ({
        ...(customerData || {}),
        returnAddress: customerData?.returnAddress || {},
      }),
    }),

    street() {
      const street = [];

      if (this.customerData.returnAddress.street) {
        street.push(this.customerData.returnAddress.street);
      }
      if (this.customerData.returnAddress.street1) {
        street.push(this.customerData.returnAddress.street1);
      }
      if (this.customerData.returnAddress.street2) {
        street.push(this.customerData.returnAddress.street2);
      }

      return street.join(', ') || '-';
    },
  },
};
</script>
