<template>
  <v-layout column>
    <returned-package-info
      :loading="isLoading"
      :loading-error="loadingError"
      @refresh="$_fetchData"
    />

    <returned-package-customer-data
      :loading="isLoading"
      :loading-error="loadingError"
      @refresh="$_fetchData"
    />

    <returned-package-history
      :loading="isLoading"
      :loading-error="loadingError"
      @refresh="$_fetchData"
    />
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex';

import { useDataLoading } from '@mixins/factories';

import ReturnedPackageInfo from '../_components/ReturnedPackageInfo.vue';
import ReturnedPackageHistory from '../_components/ReturnedPackageHistory.vue';
import ReturnedPackageCustomerData from '../_components/ReturnedPackageCustomerData.vue';

const dataLoadingOptions = {
  getterName: 'getReturnedPackage', searchable: false, paged: false, parameterize: true,
};

export default {
  name: 'ReturnedPackage',

  mixins: [
    useDataLoading(dataLoadingOptions),
  ],

  components: {
    ReturnedPackageInfo,
    ReturnedPackageHistory,
    ReturnedPackageCustomerData,
  },

  methods: {
    ...mapActions('returnPackages', ['getReturnedPackage']),
  },
};
</script>
